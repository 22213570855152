@import "../../../globalStyles/scss/variables.scss";

.login-page {
  margin-top: 10px;
  margin-bottom: 150px;
}

.description {
  margin-top: 10px;
  max-width: 600px;
}

.form {
  margin-top: 40px;
  max-width: 500px;

  > div {
    width: 100%;
  }

  @media (max-width: $small-screen) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}